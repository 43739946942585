import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import React from 'react'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {KenticoSitemap, KenticoSitemapData} from '~/kentico/components/sitemap'
import {CookieError} from '~/common/components/cookie-error'
import {GatsbyLayout} from '../components/layout'
import {useDecode} from '../util'

const Props = IO.type({
  data: IO.type({
    globalScripts: KenticoGlobalScriptData,
    page: KenticoSitemapData,
  }),
})

/** Query from template. */
export const query = graphql`
  query($id: String!) {
    page: kontentItemSitemap(id: {eq: $id}) {
      ...KenticoSitemap
    }
    ...KenticoGlobalScripts
  }
`

const Sitemap = (props: unknown) => {
  const {data} = useDecode(Props, props)
  return (
    <GatsbyLayout contentId={data.page.system.id}>
      <CookieError />
      <KenticoSitemap {...data} />
    </GatsbyLayout>
  )
}

/** Sitemap. */
// eslint-disable-next-line import/no-default-export
export default Sitemap
