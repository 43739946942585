import React from 'react'
import {Link} from '~/common/components/link'
import {GatsbySEO} from '~/gatsby/components/seo'
import {createReplace} from '../../renderer'
import {createUrl, getLinkText, linkedItems} from '../../util'
import {KenticoBodyItem} from '../body-item'
import {KenticoGlobalScriptData} from '../global-script'
import {KenticoPreviewLink} from '../preview-link'
import {KenticoSitemapData} from './data'

interface Props {
  page: KenticoSitemapData
  globalScripts: KenticoGlobalScriptData
}

/** Kentico type data. */
export {KenticoSitemapData}

/**
 * Render sitemap from Kentico data.
 * @return React component
 */
export const KenticoSitemap = ({page, globalScripts}: Props) => {
  const children = linkedItems(page.elements.pages).map(p => {
    try {
      const url = createUrl(p)
      const text = getLinkText(p)
      return (
        <li key={p.system.id}>
          <KenticoPreviewLink contentItem={p.system.id}>
            Edit&nbsp;
          </KenticoPreviewLink>
          <Link to={url}>{text}</Link>
        </li>
      )
    } catch {
      return undefined
    }
  })

  return (
    <>
      <GatsbySEO
        description={page.elements.description.value}
        globalScripts={globalScripts}
        page={page}
        pageScripts={page.elements.scripts}
        replace={createReplace}
        robots={page.elements.robots}
        title={page.elements.title.value}
      />
      <KenticoBodyItem data={linkedItems(page.elements.preBody)} />
      <h1>{page.elements.title.value}</h1>
      <ul>{children}</ul>
      <KenticoBodyItem data={linkedItems(page.elements.postBody)} />
    </>
  )
}
